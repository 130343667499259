import React, { useEffect, useState } from 'react'
import { functions, useApp, useWidth } from '@wap-client/core'
import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Button from '@/components/base/button/Button'
import Icon from '@/components/base/icon'
import LanguageSwitch from '@/components/widgets/language-switch'

import { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  logo,
  menu,
  pageTitle,
  isGradient,
  homepage,
}) => {
  const app = useApp()
  const width = useWidth()

  const [menuActive, setMenuActive] = useState(false)

  useEffect(() => {
    if (!pageTitle && width > 1024) {
      setMenuActive(true)
    } else {
      setMenuActive(false)
    }
  }, [pageTitle, width])

  return (
    <header
      className={functions.classnames(
        'header',
        menuActive && 'header--menu-active',
        isGradient && 'header--gradient'
      )}
    >
      <div className="header__top">
        <Container size="extended">
          <Row>
            <Column
              xs={{ auto: true, offset: 1 }}
              className="header__top__menu"
            >
              {pageTitle && width > 1025 && (
                <Button
                  className="menu__button"
                  onClick={() => setMenuActive((prevState) => !prevState)}
                >
                  {app.settings.translations['menu']}
                  <Icon name="manu" size="small" />
                </Button>
              )}
              <LanguageSwitch />
            </Column>
          </Row>
        </Container>
      </div>
      <div className="header__body">
        <Container size="extended">
          <Row xs={{ justify: 'between', align: 'center' }}>
            {logo && (
              <Column
                xs={{ size: 12 }}
                sm={{ size: 12 }}
                md={{ size: 1 }}
                lg={{ size: 1 }}
                xl={{ size: 1 }}
              >
                <div className="header__body__logo">
                  <figure>
                    <Anchor {...homepage}>
                      <Image {...logo} alt={app.settings.shortName} />
                    </Anchor>
                  </figure>

                  {pageTitle && width < 1025 && (
                    <div
                      className={functions.classnames(
                        'header__body__page__title',
                        menuActive && 'header__body__page__title--disable'
                      )}
                    >
                      {pageTitle}
                    </div>
                  )}

                  {width < 1025 && (
                    <Button
                      className="menu__button menu__button--mobile"
                      onClick={() => setMenuActive((prevState) => !prevState)}
                    >
                      <Icon name="manu" size="large" />
                    </Button>
                  )}
                </div>
              </Column>
            )}

            {menu && (
              <Column
                xs={{ size: 12 }}
                sm={{ size: 12 }}
                md={{ size: 11 }}
                lg={{ size: 11 }}
                xl={{ size: 11 }}
              >
                {pageTitle && width > 1025 && (
                  <div
                    className={functions.classnames(
                      'header__body__page__title',
                      menuActive && 'header__body__page__title--disable'
                    )}
                  >
                    {pageTitle}
                  </div>
                )}
                <nav
                  className={functions.classnames(
                    'header__body__menu',
                    menuActive && 'header__body__menu--active'
                  )}
                >
                  <ul>
                    {menu.map((item, index) => {
                      return (
                        <li key={index}>
                          <Anchor
                            onMouseOver={(e) =>
                              (e.target as HTMLElement).classList.add('active')
                            }
                            onMouseOut={(e) =>
                              (e.target as HTMLElement).classList.remove(
                                'active'
                              )
                            }
                            href={item.href}
                            onClick={() => setMenuActive(false)}
                          >
                            {item.title}
                          </Anchor>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </Column>
            )}
          </Row>
        </Container>
      </div>

      {width < 1025 && (
        <div
          className={functions.classnames(
            'header__contacts',
            menuActive && 'header__contacts--active'
          )}
        >
          <Container size="extended">
            <Row>
              <div className="header__contacts__item mail">
                <a
                  href={`mailto:${app.settings.translations['mail']}`}
                  target="_blank"
                >
                  {app.settings.translations['mail']}
                </a>
              </div>

              <div className="header__contacts__item social__list">
                <Anchor
                  className="social__list__item"
                  href={app.settings.socials['youtube']}
                >
                  <Icon name="youtube" />
                </Anchor>

                <Anchor
                  className="social__list__item"
                  href={app.settings.socials['linkedin']}
                >
                  <Icon name="linkedin" />
                </Anchor>

                <Anchor
                  className="social__list__item"
                  href={app.settings.socials['instagram']}
                >
                  <Icon name="insta" />
                </Anchor>
              </div>
            </Row>
          </Container>
        </div>
      )}
    </header>
  )
}

export default Header
